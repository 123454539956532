import * as actionTypes from './actionTypes';
import getInstance from '../../instances/axios/withAuth'

import showToastr from './toastr'
import {updateProgressBar} from './index';

import {saveAs} from "file-saver"

export const sendNewProtectedArea = (formData, clearForm) => {
  // console.log('[CLEAR FORM]',clearForm)
  return dispatch => {
    getInstance().put('/areas-protecao/', formData, {
      headers: {'Content-Type': 'multipart/form-data' },
      onUploadProgress: function(progressEvent) {
        dispatch(updateProgressBar(Math.round( (progressEvent.loaded * 100) / progressEvent.total )))
      }
    }).then(response => {
      // clearForm();
      dispatch(showToastr('Área de Proteção adicionada!', 'A área cadastrada encontra-se disponível para visualização no mapa.', "success"));
      dispatch(updateProgressBar(0))
    }).catch(err => {
      console.log(err);
      dispatch(updateProgressBar(0));
      dispatch(showToastr('Falha ao cadastrar', 'Ocorreu algum problema. Tente novamente.', "error"));
    })
  }
};

export const sendNewAmbientalData = (formData, clearForm) => {
  // console.log('[CLEAR FORM]',clearForm)
  return dispatch => {
    getInstance().put('/ambiental/', formData, {
      headers: {'Content-Type': 'multipart/form-data' },
      onUploadProgress: function(progressEvent) {
        dispatch(updateProgressBar(Math.round( (progressEvent.loaded * 100) / progressEvent.total )))
      }
    }).then(response => {
      dispatch(showToastr('Aplicação adicionada!', 'A aplicação cadastrada encontra-se disponível para visualização no mapa.', "success"));
      dispatch(updateProgressBar(0))
    }).catch(err => {
      console.log(err);
      dispatch(updateProgressBar(0));
      dispatch(showToastr('Falha ao cadastrar', 'Ocorreu algum problema. Tente novamente.', "error"));
    })
  }
};

const actionFetchAreas = (areas) => {
  return {
    type: actionTypes.AMBIENTAL_FETCH_PROTECTION_AREAS,
    payload: areas
  }
};

export const fetchAllProtectedAreas = () => {
  return dispatch => {
    getInstance().get('/areas-protecao/')
      .then(response => {
        dispatch(actionFetchAreas(response.data))
      })
  }
};


const loadAmbientalFromUUID = (laudo, uuid) => {
  return {
    type: actionTypes.AMBIENTAL_SUCCESS_LOADING_NEW,
    payload: {
      [uuid]: laudo
    }
  }
};

export const getAmbientalFromUUID = (uuid, rdy) => {
  return dispatch => {
    dispatch({type: actionTypes.AMBIENTAL_START_LOADING_NEW, payload: {selected: uuid}});
    if (!rdy) {
      return
    }
    getInstance().get('/ambiental/' + uuid, {
      onDownloadProgress: function(progressEvent) {
        dispatch(updateProgressBar(Math.round( (progressEvent.loaded * 100) / progressEvent.total )))
      }
    }).then(laudo => {
      dispatch(loadAmbientalFromUUID(laudo.data,
        uuid));
      dispatch(updateProgressBar(0))
    }).catch(err => {
      dispatch(updateProgressBar(0))

      dispatch({type: actionTypes.AMBIENTAL_ERROR_LOADING_NEW});
      console.log(err);
    })
  }
}

export const getFileFromUUID = (uuid) => {
  return dispatch => {
    getInstance().get('/ambiental/anexos/' + uuid, {
      onDownloadProgress: function(progressEvent) {
        dispatch(updateProgressBar(Math.round( (progressEvent.loaded * 100) / progressEvent.total )))
      },
      responseType: 'arraybuffer'
    }).then(response => {
      console.log(response.data);
      let blob = new Blob([response.data], {
        type: 'application/pdf'
      });
      let url = window.URL.createObjectURL(blob)
      window.open(url);
      dispatch(updateProgressBar(0))
    }).catch(err => {
      dispatch(updateProgressBar(0))
      console.log(err);
    })
  }
};

export const getOsFileFromUUID = (uuid) => {
  return dispatch => {
    getInstance().get('/ambiental/os/' + uuid, {
      onDownloadProgress: function(progressEvent) {
        dispatch(updateProgressBar(Math.round( (progressEvent.loaded * 100) / progressEvent.total )))
      },
      responseType: 'arraybuffer'
    }).then(response => {
      console.log(response.data);
      let blob = new Blob([response.data], {
        type: 'application/pdf'
      });
      saveAs(blob, uuid+".pdf");
      dispatch(updateProgressBar(0))
    }).catch(err => {
      dispatch(updateProgressBar(0))
      console.log(err);
    })
  }
};

export const removeOsFromUUID = (uuid) => {
  return dispatch => {
    getInstance().delete('/ambiental/os/' + uuid).then(response => {
      console.log(response.data);
      dispatch({
        type: actionTypes.REMOVE_OS_FROM_LIST,
        payload: uuid
      });
      dispatch(showToastr('OS removida', 'A ordem de serviço selecionada foi removida com sucesso!', "success"));
    }).catch(err => {
      dispatch(showToastr('Falha ao remover', 'Ocorreu algum problema. Tente novamente.', "error"));
      console.log(err);
    })
  }
};

const loadAmbientalList = (laudo, uuid) => {
  return {
    type: actionTypes.AMBIENTAL_SUCCESS_LOADING_LIST,
    payload: {
      list: laudo
    }
  }
};

export const getAmbientalList = (formData) => {
  return dispatch => {
    dispatch({type: actionTypes.AMBIENTAL_START_LOADING_LIST});
    getInstance().post('/ambiental/', formData).then(list => {
      dispatch(loadAmbientalList(list.data));
    }).catch(err => {
      dispatch({type: actionTypes.AMBIENTAL_ERROR_LOADING_LIST});
      console.log(err);
    })
  }
}


export const sendNewServiceOrder = (formData, clearForm) => {
  // console.log('[CLEAR FORM]',clearForm)
  return dispatch => {
    getInstance().put('/ambiental/os/', formData, {
      headers: {'Content-Type': 'multipart/form-data' },
      onUploadProgress: function(progressEvent) {
        dispatch(updateProgressBar(Math.round( (progressEvent.loaded * 100) / progressEvent.total )))
      }
    }).then(response => {
      clearForm();
      dispatch(showToastr('Ordem de Serviço adicionada!', 'A OS cadastrada encontra-se disponível para visualização na tabela.', "success"));
      dispatch(updateProgressBar(0))
    }).catch(err => {
      console.log(err);
      dispatch(updateProgressBar(0));
      dispatch(showToastr('Falha ao cadastrar', 'Ocorreu algum problema. Tente novamente.', "error"));
    })
  }
};

export const fetchUserList = () => {
  return async dispatch => {
    try {
      const users = (await getInstance().get('/ambiental/users/')).data;

      if (users.length === 0) return dispatch(showToastr('Falha ao listar usuários', 'Não foram encontrados usuários.', "error"));

      const mappedUsers = users.map(el => {
        const user = {};
        user.nome = el.nome;
        user.tipo = el.nivel === 'funcionario' ? 'ADECO' : el.nivel.toUpperCase();
        user.tipo = el.nivel === 'ministerio' ? 'ADECO' : user.tipo;
        user.email = el.email;
        user.id = el.id;
        if (el.funcionario) {
          user.funcao = el.funcionario.funcao
            .replace("NONE ", "")
            .replace("[1]", "Programação ")
            .replace("[2]", "Coordenador ")
            .replace("[3]", "Gerente ")
        }else {
          user.funcao = el.nivel === 'ministerio' ? "Ministério Público" : "Interno DS"
        }
        return user
      });
      return dispatch({
        type: actionTypes.FETCH_USERS,
        payload: mappedUsers
      })
    } catch (e) {
      console.log(e)
    }
  }
};



export const removeUserFromId = (id) => {
  return dispatch => {
    getInstance().delete('/ambiental/users/' + id).then(response => {
      console.log(response.data);
      dispatch({
        type: actionTypes.REMOVE_USER_FROM_ID,
        payload: id
      });
      dispatch(showToastr('Usuário removido', 'O usuário selecionado foi removido com sucesso!', "success"));
    }).catch(err => {
      dispatch(showToastr('Falha ao remover', 'Ocorreu algum problema. Tente novamente.', "error"));
      console.log(err);
    })
  }
};

export const removeReportFromUUID = (id) => {
  return dispatch => {
    getInstance().delete('/ambiental/' + id).then(response => {
      console.log(response.data);
      dispatch({
        type: actionTypes.REMOVE_REPORT_UUID,
        payload: id
      });
      dispatch(showToastr('Aplicação removida', 'A aplicação selecionada foi removida com sucesso!', "success"));
    }).catch(err => {
      dispatch(showToastr('Falha ao remover', 'Ocorreu algum problema. Tente novamente.', "error"));
      console.log(err);
    })
  }
};
